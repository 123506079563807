
.left-aligner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.card-bodyy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    border-right: 3px solid black;
}

@media (max-width: 768px) {
    .card-bodyy {
        border-right: none;
        border-bottom: 3px solid black;
    }

    .left-aligner {
        justify-content: center;
    }

}

.main-heading {
    font-weight: bolder;
    text-align: center;
    color: #e59edd;
    opacity: 0.2;
}

.main-heading {
    font-size: 13rem;
}

@media (max-width: 1450px) {
    .main-heading {
        font-size: 12em;
    }
}

@media (max-width: 1362px) {
    .main-heading {
        font-size: 10em;
    }
}

@media (max-width: 1144px) {
    .main-heading {
        font-size: 9em;
    }
}

@media (max-width: 1050px) {
    .main-heading {
        font-size: 8rem;
    }
}

@media (max-width: 924px) {
    .main-heading {
        font-size: 7em;
    }
}

@media (max-width: 825px) {
    .main-heading {
        font-size: 5em;
    }
}

@media (max-width: 625px) {
    .main-heading {
        font-size: 3em;
    }
}

.positioned-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60%;
    left: 33%;
    transform: translate(-50%, -50%);
    width: 100%;
}

@media (max-width: 768px) {
    .positioned-container {
        left: 53%;
    }
}