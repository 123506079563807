.marginal {
    margin: 3rem !important;
}

@media (max-width: 460px) {
    .marginal {
        margin: 0 !important;
    }
}

.marginal2 {
    margin: 1.5rem !important;
}

@media (max-width: 460px) {
    .marginal2 {
        margin: 0 !important;
    }
}

