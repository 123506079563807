
.offers-list-item {
    font-size: 1rem; 
}

@media (max-width: 768px) {
    .offers-list-item {
        font-size: 0.7rem; 
    }
}

@media (max-width: 480px) {
    .offers-list-item {
        font-size: 0.8rem; 
    }
}
.offers-list-item img {
    height: 18px; 
}
@media (max-width: 768px) {
    .offers-list-item img {
        height: 15px; 
    }
}
@media (max-width: 480px) {
    .offers-list-item img {
        height: 10px; 
    }
}

.offers-h3 {
    font-size: 1.5rem; 
}

.offers-h5 {
    font-size: 1rem; 
}

@media (max-width: 768px) {
    .offers-h3 {
        font-size: 1.25rem; 
    }

    .offers-h5 {
        font-size: 0.875rem; 
    }
}

@media (max-width: 480px) {
    .offers-h3 {
        font-size: 1rem; 
    }

    .offers-h5 {
        font-size: 0.75rem; 
    }
}
