.home-container {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
}

@media (max-width: 800px) {

    .home-container {
        margin-top: 28vh;
    }
}