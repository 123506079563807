.review-img {
    display: flex;
    justify-content: end;
    align-items: center;
    border-right: 4px solid black;
}

@media (max-width: 768px) {
    .review-img {
        justify-content: center;
        border-right: none;
        border-bottom: 4px solid black;
    }
}