img.toggle {
    width: 40px;
}

@media (max-width: 768px) {
    img.toggle {
        width: 30px;
    }
}

.toggle-bar-nav.open {
    right: 0;
}


.body-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
.custom-navbar-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
}

@media (max-width: 786px) {
    .custom-navbar-container {
        margin-right: 40px;
    }
}
/* @media (max-width: 460px) {
    .custom-navbar-container {
        margin-right: 120px;
    }
} */

@media (max-width: 460px) {
    .custom-navbar-container {
        margin-right: 40px;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
}

.navbar-logo-img {
    width: 600px;
}

.navbar-nav .nav-link {
    color: #e59edd;
    text-decoration: none;
    font-size: 1rem;
}

.toggle {
    cursor: pointer;
}

.navbar.scrolled {
    background-color: #8bc6ff;
}

@media (max-width: 1730px) {
    .custom-navbar-container {
        flex: 2 / 3;
    }
}

@media (max-width: 1450px) {
    .navbar-logo-img {
        width: 450px;
    }
}

@media (max-width: 1050px) {
    .navbar-logo-img {
        width: 300px;
    }
    .navbar-nav .nav-link {
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .navbar-logo-img {
        width: 200px;
    }
}
