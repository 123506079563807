.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sticky-navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-nav {
  margin-left: auto;
}

.navbar-brand img {
  max-width: 500px;
}

.custom-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-fonts {
  font-size: large;
  font-weight: 500;
  color: white;
}

.navbar {
  transition: background-color 0.5s ease;
}

.navbar.scrolled {
  background-color: #8bc6ff;
}

.navbar.scrolled .nav-link {
  color: white !important;
}

.nav-link:hover {
  color: #4e95d9 !important;
}

.navbar.scrolled .nav-link:hover{
  color: #ff69b4 !important;
}

.carousel .control-dots .dot {
  background-color: #606de4 !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
}

.carousel .control-dots .dot.selected {
  background-color: #ff69b4;
}

.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 980px) {
  .services-container {
    flex-direction: column;
  }
}

.overall {
  width: 400px;
}

@media (max-width: 768px) {
  .overall {
    width: 300px;
  }
}

@media (max-width: 580px) {
  .overall {
    width: 250px;
  }
}