.website-content-img {
    height: 350px;

    @media (max-width: 1730px) {
        height: 290px;
    }

    @media (max-width: 1310px) {
        height: 240px;
    }

    @media (max-width: 1186px) {
        height: 200px;
    }

    @media (max-width: 930px) {
        height: 170px;
    }

    @media (max-width: 768px) {
        height: 140px;
    }
}

.website-content-title {
    opacity: 0.2;
    font-size: 13vw;
    font-weight: bolder;
    text-align: center;
    color: white;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 730px) {
    .website-content-title {
        top: 30%;
    }
}

@media (max-width: 505px) {
    .website-content-title {
        top: 40%;
    }
}