.success-metric {
    width: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    margin-bottom: 3rem;
    margin-right: 2px;
}

.success-metric img {
    width: 100%;
    height: auto;
    align-self: center;
    justify-self: center;
}

.success-metric .card-body h1 {
    font-size: 3rem;
    font-weight: bolder;
    color: white;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}

@media (max-width: 900px) {
    .success-metric {
        width: 50%;
        margin: 1rem 0;
    }

    .success-metric .card-body h1 {
        font-size: 2rem;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .success-metric .card-body h5 {
        font-size: 1rem;
    }

    .success-metrics-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    /* .success-metric img {
        width: 50px;
        height: 50px;
        margin-right: 50px;
    } */

    .success-metric .card-body h1 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .success-metric img {
        width: 60px;
        height: 60px;
    }

    .success-metric .card-body h1 {
        font-size: 1rem;
    }
}