.card {
    /* width: 28rem; */
    height: 15rem;
    margin: 10px;
    background-color: white;
    color: black;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.card:hover {
    background-color: #e59edd;
    color: white;
}

.card-img-top {
    height: 100px;
    width: 100px;
    align-self: center;
    justify-self: center;
}

@media (min-width: 980px) {
    .card {
        width: 18rem;
        height: 20rem;
    }
}