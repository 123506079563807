.navbar-fontss {
    font-size: 20px;
    color: white !important;
}

img.navbar-logo {
    height: 90px;
}

.cross {
    height: 50px;
}

@media (max-width: 864px) {
    .navbar-fonts {
        font-size: 18px;
    }

    img.navbar-logo {
        height: 60px;
    }

    .cross {
        height: 40px;
    }
}

@media (max-width: 768px) {
    .navbar-fonts {
        font-size: 16px;
    }

    img.navbar-logo {
        height: 40px;
    }

    .cross {
        height: 30px;
    }
}

.toggle-bar-nav {
    position: fixed;
    background-color: #8bc6ff;
    border-left: 1px solid grey;
    color: white;
    height: 100vh;
    width: 50vw;
    top: 0;
    right: -50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    z-index: 1000;
    transition: right 0.5s ease-in-out;
}

.toggle-bar-nav.open {
    right: 0;
}