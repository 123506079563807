.topnav {
    width: 70vw;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

@media (max-width: 1600px) {
    .topnav {
        width: 85vw;
    }
}

@media (max-width: 1200px) {
    .topnav {
        width: 95vw;
    }
}

.responsive-logo {
    width: 600px;
}

.responsive-text {
    width: 500px;
}

@media (max-width: 1026px) {
    .responsive-logo {
        width: 400px;
    }

    .responsive-text {
        width: 400px;
    }
}

@media (max-width: 1026px) {
    .responsive-logo {
        width: 250px;
    }
}

.flex-container {
    display: flex;
    height: 35vh;
    flex-direction: row;
}

.flex-item {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.flex-content {
    display: flex;
    justify-content: end;
    align-items: center;
}

@media (max-width: 870px) {
    .flex-container {
        flex-direction: column;
    }

    .flex-content {
        justify-content: center;
    }

    .flex-itemm {
        text-align: center;
    }

    .flex-itemm hr {
        width: 50%;
        margin: 10px auto;
    }
}


@media (max-width: 870px) {
    .parasize {
        font-size: 18px;
    }
}

@media (max-width: 460px) {
    .parasize {
        font-size: 12px;
    }
}